import { Box, Button, Flex, Input, Tag } from "@chakra-ui/react";
import { EyeOffIcon } from "@heroicons/react/solid";
import { IContentCategory } from "../../../../../models/contentCategory";

interface IContentListTagSelectionProps {
  categoryFilterState: IContentCategory[];
  contentCategoryIsEqual: any;
  filteredTags: {
    id: number;
    name: any;
    counter: number;
  }[];
  setShowTagArea: any;
  setTagSearchString: any;
  showTagArea: boolean;
  tagSearchString: string;
  toggleCategory: any;
}

export const ContentListTagSelection = ({
  categoryFilterState,
  contentCategoryIsEqual,
  filteredTags,
  setShowTagArea,
  setTagSearchString,
  showTagArea,
  tagSearchString,
  toggleCategory
}: IContentListTagSelectionProps) => {
  return (
    <Box
      w={"full"}
      mb={4}
      mx={0}
      shadow="sm"
      borderBottom="1rem solid"
      borderColor={"blackAlpha.300"}
      pos={"relative"}
      borderRadius={"3xl"}
      overflow={"hidden"}
    >
      <Box w={"full"} position={"sticky"} borderColor={"darkBlue.500"} dropShadow={"xl"}>
        <Flex justifyContent={"space-between"} p={4} alignItems={"center"} bg={"blackAlpha.300"}>
          <Input
            bgColor={"white"}
            value={tagSearchString}
            size="sm"
            borderRadius={"3xl"}
            mr={2}
            w={"20rem"}
            placeholder={"Nach Tags suchen..."}
            type={"text"}
            onInput={(e: any) => {
              setTagSearchString(e.target.value);
            }}
          />
          <Button
            borderRadius={"3xl"}
            onClick={() => setShowTagArea(!showTagArea)}
            size={"sm"}
            leftIcon={<EyeOffIcon className="w-5 h-5 text-white" />}
          >
            Ausblenden
          </Button>
        </Flex>
      </Box>

      <Flex
        className={"tagArea-scrollbar--dark"}
        flexWrap={"wrap"}
        bg={"whiteAlpha.100"}
        gap={4}
        p={4}
        boxShadow={"inner"}
        maxH={{ base: "60px", sm: "70px", lg: "100px" }}
        overflowY={"scroll"}
        transition={"all 0.5s ease-in-out"}
      >
        <>
          {filteredTags.length === 0 && <>Keine Tags zu dieser Suche</>}
          {filteredTags.map((categoryFilter, index) => (
            <Tag
              key={index}
              borderRadius={"3xl"}
              colorScheme={
                categoryFilterState.some(obj => contentCategoryIsEqual(obj.id, categoryFilter.id)) ? "darkBlue" : "gray"
              }
              color={categoryFilterState.some(obj => contentCategoryIsEqual(obj.id, categoryFilter.id)) ? "white" : "gray.700"}
              size={"md"}
              cursor={"pointer"}
              onClick={() => {
                toggleCategory(categoryFilter);
              }}
            >
              {categoryFilter.name}
              <Box mx="2" fontWeight={600}>
                {categoryFilter.counter}
              </Box>
            </Tag>
          ))}
        </>
      </Flex>
    </Box>
  );
};
